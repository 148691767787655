import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthRouteSuccessMessageMap } from '@app/+auth/mappings';
import {
  EmailModel,
  LoginModel,
  LoginResponseModel,
  LoginUserResponseModel,
  PasswordChangeModel,
  RegisterModel,
  UserResponseModel
} from '@app/+auth/models';
import { flattenUser } from '@app/+auth/utils';
import { environment } from '@environments/environment';
import {
  DEFAULT_LOGGED_IN_PATH,
  NOT_COMPLETE_LOGGED_IN_PATH,
  RESTRICTED_COMPANY_LOGGED_IN_PATH
} from '@shared/constants';
import {
  ApiResourceEnum,
  AuthRouterPathEnum,
  CompanyStatusEnum,
  SessionStorageEnum,
  StatusTypeEnum
} from '@shared/enums';
import { MessageResponseModel, UserModel, UserProfileModel } from '@shared/models';
import { getHttpHeaders } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private ngZone: NgZone
  ) {}

  public login(data: LoginModel): Observable<UserModel> {
    return this.http
      .post<LoginResponseModel>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.Login),
        data,
        {
          headers: getHttpHeaders()
        }
      )
      .pipe(switchMap(this.setUser));
  }

  public loginUser(data: LoginModel): Observable<LoginUserResponseModel> {
    return this.http.post<LoginUserResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.Login),
      data,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public otpCheck(otpCode: string, userCode: string): Observable<any> {
    return this.http.post<any>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.OtpAuth),
      { otp_code: otpCode, user_code: userCode },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json'
        })
      }
    );
  }

  public resendOtpCode(userCode: string): Observable<any> {
    return this.http.post<any>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.OtpResend),
      { user_code: userCode },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json'
        })
      }
    );
  }

  public getScheduler(code: string): Observable<any> {
    return this.http.get(`${environment.API_URL}/self-schedule/${code}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json'
      })
    });
  }

  public reloadScheduler(code: string): Observable<any> {
    return this.http.get(`${environment.API_URL}/reload-schedule/${code}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json'
      })
    });
  }

  public selfSchedule(data: {}, code: string): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}/self-schedule/${code}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json'
      })
    });
  }

  public confirmSelfSchedule(data: {}, code: string): Observable<any> {
    return this.http.post(`${environment.API_URL}/self-schedule-confirm/${code}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json'
      })
    });
  }

  public getUser(): Observable<UserModel> {
    return this.http
      .get<{ user: UserResponseModel }>(
        this.getEndpoint(environment.API_URL, ApiResourceEnum.GetUser),
        {
          headers: getHttpHeaders()
        }
      )
      .pipe(switchMap(this.setUser));
  }

  public register(data: RegisterModel): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.Register),
      data,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public confirmEmail(token: string): Observable<MessageResponseModel> {
    return this.http.get<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.ConfirmEmail, token),
      {
        headers: getHttpHeaders()
      }
    );
  }

  public confirmTerms(id: number): Observable<UserModel> {
    return this.http
      .patch<{ user: UserResponseModel }>(
        this.getEndpointWithRouteBinding(
          environment.API_URL,
          ApiResourceEnum.AcceptTermsAndConditions,
          id
        ),
        {
          headers: getHttpHeaders()
        }
      )
      .pipe(switchMap(this.setUser));
  }

  public passwordReset(data: EmailModel): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.PasswordReset),
      data,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public resendVerificationEmail(data: EmailModel): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.ResendVerificationEmail),
      data,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public passwordChange(data: PasswordChangeModel): Observable<MessageResponseModel> {
    return this.http.post<MessageResponseModel>(
      this.getEndpoint(environment.API_URL, ApiResourceEnum.PasswordChange),
      data,
      {
        headers: getHttpHeaders()
      }
    );
  }

  public navigate(
    termsAccepted: boolean,
    userProfile: UserProfileModel,
    forceReload: boolean = false
  ): void {
    if (!termsAccepted) {
      this.router.navigate([AuthRouterPathEnum.AcceptTermsAndConditions]);
      return;
    }
    let redirectRoute = DEFAULT_LOGGED_IN_PATH;
    if (userProfile.status !== StatusTypeEnum.Active) {
      redirectRoute = NOT_COMPLETE_LOGGED_IN_PATH + '/' + userProfile.id;
    } else if (userProfile.company_status === CompanyStatusEnum.Restricted) {
      redirectRoute = RESTRICTED_COMPANY_LOGGED_IN_PATH;
    } else {
      const referralUrl = localStorage.getItem(SessionStorageEnum.ReferralUrl);
      if (referralUrl) {
        redirectRoute = referralUrl;
        localStorage.removeItem(SessionStorageEnum.ReferralUrl);
      }
    }

    if (forceReload) {
      this.location.go(redirectRoute);
      return this.reload();
    }
    this.router.navigate([redirectRoute]);
  }

  public navigateOnSuccess(path: AuthRouterPathEnum): void {
    const navigationExtras: NavigationExtras = {
      state: { message: AuthRouteSuccessMessageMap[path] }
    };
    this.router.navigate([path], navigationExtras);
  }

  public logout(): void {
    localStorage.removeItem(SessionStorageEnum.AccessToken);
    localStorage.removeItem(SessionStorageEnum.SelectedCompany);
    localStorage.removeItem(SessionStorageEnum.SelectedEmployee);
    localStorage.removeItem(SessionStorageEnum.ProjectInspectionData);
    localStorage.removeItem(SessionStorageEnum.GeneralData);
    this.router.navigate([AuthRouterPathEnum.Login]);
  }

  private setUser({
    user,
    token = null
  }: {
    user: UserResponseModel;
    token: string | null;
  }): Observable<UserModel> {
    if (token) {
      localStorage.setItem(SessionStorageEnum.AccessToken, token);
    }
    return of(flattenUser(user));
  }

  private getEndpoint(baseUrl: string, resource: ApiResourceEnum, param?: string): string {
    const routeParam: string = param ? `/${param}` : '';
    return `${baseUrl}/${resource}${routeParam}`;
  }

  private getEndpointWithRouteBinding(
    baseUrl: string,
    resource: ApiResourceEnum,
    id: number
  ): string {
    const path: string = resource.replace(':id', id.toString());
    return `${baseUrl}/${path}`;
  }

  private reload(): void {
    return this.ngZone.runOutsideAngular(() => {
      location.reload();
    });
  }
}
