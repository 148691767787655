import { AuthRouterRelativePathEnum } from '@shared/enums';
import { AuthRouteDataModel } from '@shared/models';

const loginRouteDataConfig: AuthRouteDataModel = {
  title: 'auth.common.title',
  subTitle: 'auth.login.sub-title'
};

export const AuthRouteDataMap: { [key in AuthRouterRelativePathEnum]: AuthRouteDataModel } = {
  [AuthRouterRelativePathEnum.Login]: loginRouteDataConfig,
  [AuthRouterRelativePathEnum.AcceptTermsAndConditions]: loginRouteDataConfig,
  [AuthRouterRelativePathEnum.Register]: {
    title: 'auth.common.title',
    subTitle: 'auth.register.sub-title'
  },
  [AuthRouterRelativePathEnum.RegisterSuccess]: {
    title: 'auth.common.title',
    subTitle: 'auth.common.sub-title.success'
  },
  [AuthRouterRelativePathEnum.EmailConfirmation]: {
    title: 'auth.common.title',
    subTitle: 'auth.email.sub-title.verified'
  },
  [AuthRouterRelativePathEnum.PasswordReset]: {
    title: 'auth.password-reset.title',
    subTitle: 'auth.password-reset.sub-title'
  },
  [AuthRouterRelativePathEnum.PasswordResetSuccess]: {
    title: 'auth.password-reset.title',
    subTitle: 'auth.common.sub-title.thanks'
  },
  [AuthRouterRelativePathEnum.PasswordChange]: {
    title: 'auth.password-reset.title',
    subTitle: 'auth.password-change.sub-title'
  },
  [AuthRouterRelativePathEnum.PasswordChangeSuccess]: {
    title: 'auth.password-reset.title',
    subTitle: 'auth.common.sub-title.success'
  },
  [AuthRouterRelativePathEnum.ResendVerificationEmail]: {
    title: 'auth.link.resend-verification-email',
    subTitle: 'auth.password-reset.sub-title'
  },
  [AuthRouterRelativePathEnum.ResendVerificationEmailSuccess]: {
    title: 'auth.link.resend-verification-email',
    subTitle: 'auth.common.sub-title.success'
  },
  [AuthRouterRelativePathEnum.RegisterCompanyAccount]: {
    title: 'auth.common.title',
    subTitle: 'auth.register.sub-title'
  },
  [AuthRouterRelativePathEnum.Maintenance]: {
    title: 'auth.maintenance.title',
    subTitle: 'auth.maintenance.sub-title'
  },
  [AuthRouterRelativePathEnum.SelfSchedule]: {
    title: 'auth.common.title',
    subTitle: 'auth.self-schedule.sub-title'
  }
};
