export const environment = {
  // API_DOMAIN: 'localhost',
  // API_URL: '/api',
  // API_DOMAIN: 'archon-office.loc', // KG team local setting
  // API_URL: 'http://archon-office.loc/api', // KG team local setting
  API_DOMAIN: 'api.archon.office.skyweb.dev',
  API_TECHNICIANS_DOMAIN: 'api.archon.ios.skyweb.dev',
  API_TIMECLOCK_DOMAIN: 'api.archon.timeclock.skyweb.dev',
  API_URL: 'https://api.archon.office.skyweb.dev/api',
  API_TECHNICIANS_URL: 'https://api.archon.ios.skyweb.dev/api',
  API_TIMECLOCK_URL: 'https://api.archon.timeclock.skyweb.dev/api',
  production: true,
  PUSHER_HOST: 'archon-office.loc',
  PUSHER_PORT: 6001,
  PUSHER_AUTH_ENDPOINT: 'http://archon-office.loc/api/broadcasting/auth',
  PUSHER_FORCE_TLS: false,
  PUSHER_APP_KEY: 'FQBFKIEZTHAXJCKD'
};
