export enum AuthRouterPathEnum {
  Login = '/auth/login',
  AcceptTermsAndConditions = '/auth/accept-terms-and-conditions',
  Register = '/auth/register',
  RegisterCompanyAccount = '/auth/register-account',
  RegisterSuccess = '/auth/register/success',
  EmailConfirmation = '/auth/email/confirmation/:token',
  PasswordReset = '/auth/password/reset',
  PasswordResetSuccess = '/auth/password/reset/success',
  PasswordChange = '/auth/password/change/:token/:email',
  PasswordChangeSuccess = '/auth/password/change/success',
  ResendVerificationEmail = '/auth/email/resend',
  ResendVerificationEmailSuccess = '/auth/email/resend/success',
  Maintenance = '/auth/maintenance'
}

export enum AuthRouterRelativePathEnum {
  Login = 'login',
  AcceptTermsAndConditions = 'accept-terms-and-conditions',
  Register = 'register',
  RegisterCompanyAccount = 'register-account',
  RegisterSuccess = 'register/success',
  EmailConfirmation = 'email/confirmation/:token',
  PasswordReset = 'password/reset',
  PasswordResetSuccess = 'password/reset/success',
  PasswordChange = 'password/change/:token/:email',
  PasswordChangeSuccess = 'password/change/success',
  ResendVerificationEmail = 'email/resend',
  ResendVerificationEmailSuccess = 'email/resend/success',
  Maintenance = 'maintenance',
  SelfSchedule = 'self-schedule'
}

export enum AdminRouterPathEnum {
  Contractors = '/settings/admin/contractors',
  ContractorViewRoot = '/settings/admin/contractors/view',
  ContractorView = '/settings/admin/contractors/view/:id',
  CompanyPrices = '/settings/admin/company-prices',
  CompanyPriceViewRoot = '/settings/admin/company-prices/view',
  CompanyPriceView = '/settings/admin/company-prices/view/:id',
  Prices = '/settings/admin/prices',
  PriceViewRoot = '/settings/admin/prices/view',
  PriceView = '/settings/admin/prices/view/:id',
  PriceCreate = '/settings/admin/prices/create',
  Roles = '/settings/admin/roles',
  RoleViewRoot = '/settings/admin/roles/view',
  RoleView = '/settings/admin/roles/view/:id',
  RoleCreate = '/settings/admin/roles/create',
  Permissions = '/settings/admin/permissions',
  PermissionViewRoot = '/settings/admin/permissions/view',
  PermissionView = '/settings/admin/permissions/view/:id',
  Zones = '/settings/admin/zones',
  ZoneViewRoot = '/settings/admin/zones/view',
  ZoneView = '/settings/admin/zones/view/:id',
  ZoneCreate = '/settings/admin/zones/create',
  Accounts = '/settings/admin/accounts',
  AccountViewRoot = '/settings/admin/accounts/view',
  AccountView = '/settings/admin/accounts/view/:id',
  AccountCreate = '/settings/admin/accounts/create',
  ArchonEmployees = '/settings/admin/archon-employees',
  ArchonEmployeeViewRoot = '/settings/admin/archon-employees/view',
  ArchonEmployeeView = '/settings/admin/archon-employees/view/:id',
  ArchonEmployeeCreate = '/settings/admin/archon-employees/create',
  BuildingDepartmentNotes = '/settings/admin/building-department-notes',
  GeneralContacts = '/settings/admin/general-contacts',
  PriceCategories = '/settings/admin/price-categories',
  PriceCategoryViewRoot = '/settings/admin/price-categories/view',
  PriceCategoryView = '/settings/admin/price-categories/view/:id',
  PriceCategoryCreate = '/settings/admin/price-categories/create',
  PriceServices = '/settings/admin/price-services',
  PriceServiceViewRoot = '/settings/admin/price-services/view',
  PriceServiceView = '/settings/admin/price-services/view/:id',
  PriceServiceCreate = '/settings/admin/price-services/create',
  CompanyDirectory = '/settings/admin/company-directory',
  BonusPricing = '/settings/admin/bonus-pricing'
}

export enum AdminRouterRelativePathEnum {
  Contractors = 'contractors',
  ContractorView = 'contractors/view/:id',
  CompanyPrices = 'company-prices',
  CompanyPriceView = 'company-prices/view/:id',
  Prices = 'prices',
  PriceView = 'prices/view/:id',
  PriceCreate = 'prices/create',
  Roles = 'roles',
  RoleView = 'roles/view/:id',
  RoleCreate = 'roles/create',
  Permissions = 'permissions',
  PermissionView = 'permissions/view/:id',
  Zones = 'zones',
  UnavailableDays = 'unavailable-days',
  Rebate = 'rebate',
  RebateCreate = 'rebate-create',
  RebateUpdate = 'rebate-update',
  RebateCategory = 'rebate-category',
  RebateCategoryCreate = 'rebate-category-create',
  RebateCategoryUpdate = 'rebate-category-update',
  RebateDetailsList = 'rebate-details-list',
  RebateDetailsCreate = 'rebate-details-create',
  RebateDetailsUpdate = 'rebate-details-update',
  RebateSubcategory = 'rebate-sub-category',
  RebateSubcategoryCreate = 'rebate-sub-category-create',
  RebateSubcategoryUpdate = 'rebate-sub-category-update',
  UnavailableDaysCreate = 'unavailable-days-create',
  UnavailableDaysView = 'unavailable-days-view',
  ZoneView = 'zones/view/:id',
  ZoneCreate = 'zones/create',
  Accounts = 'accounts',
  AccountView = 'accounts/view/:id',
  AccountCreate = 'accounts/create',
  ArchonEmployees = 'archon-employees',
  ArchonEmployeeView = 'archon-employees/view/:id',
  ArchonEmployeeCreate = 'archon-employees/create',
  BuildingDepartmentNotes = 'building-department-notes',
  GeneralContacts = 'general-contacts',
  PriceCategories = 'price-categories',
  PriceCategoryView = 'price-categories/view/:id',
  PriceCategoryCreate = 'price-categories/create',
  PriceServices = 'price-services',
  PriceServiceView = 'price-services/view/:id',
  PriceServiceCreate = 'price-services/create',
  CompanyDirectory = 'company-directory',
  BonusPricing = 'bonus-pricing',
  ImportantDates = 'dates'
}

export enum CompanyProfileRouterPathEnum {
  CompanyInfo = '/settings/contractor/company-info',
  Branches = '/settings/contractor/branches',
  BranchViewRoot = '/settings/contractor/branches/view',
  BranchView = '/settings/contractor/branches/view/:id',
  BranchCreate = '/settings/contractor/branches/create',
  PricingInfo = '/settings/contractor/pricing-info',
  Accounts = '/settings/contractor/accounts',
  AccountViewRoot = '/settings/contractor/accounts/view',
  AccountView = '/settings/contractor/accounts/view/:id',
  AccountCreate = '/settings/contractor/accounts/create',
  CompanyEmployees = '/settings/contractor/company-employees',
  CompanyEmployeeViewRoot = '/settings/contractor/company-employees/view',
  CompanyEmployeeView = '/settings/contractor/company-employees/view/:id',
  CompanyEmployeeCreate = '/settings/contractor/company-employees/create',
  GeneralContacts = '/settings/contractor/general-contacts',
  CompanyDirectory = '/settings/contractor/company-directory',
  PaymentInfo = '/settings/contractor/payment-info',
  GeneralSettings = '/settings/contractor/settings'
}

export enum CompanyProfileRouterRelativePathEnum {
  CompanyInfo = 'company-info',
  Branches = 'branches',
  BranchView = 'branches/view/:id',
  BranchCreate = 'branches/create',
  PricingInfo = 'pricing-info',
  Accounts = 'accounts',
  AccountView = 'accounts/view/:id',
  AccountCreate = 'accounts/create',
  AccountEmailSettings = 'accounts/email-settings/:id',
  CompanyEmployees = 'company-employees',
  CompanyEmployeeView = 'company-employees/view/:id',
  CompanyEmployeeCreate = 'company-employees/create',
  GeneralContacts = 'general-contacts',
  CompanyDirectory = 'company-directory',
  PaymentInfo = 'payment-info',
  GeneralSettings = 'settings',
  Notifications = 'notifications'
}

export enum TimeClockRouterPathEnum {
  EmployeeList = '/time-clock/employee-list',
  CurrentDay = '/time-clock/current-day',
  History = '/time-clock/history',
  UserHistory = '/time-clock/history/:id',
  HistoryViewRoot = '/time-clock/history/view',
  HistoryView = '/time-clock/history/view/:id/:date'
}

export enum TimeClockRouterRelativePathEnum {
  EmployeeList = 'employee-list',
  CurrentDay = 'current-day',
  History = 'history',
  UserHistory = 'history/:id',
  HistoryView = 'history/view/:id/:date'
}

export enum ProjectOptionsRouterPathEnum {
  HomeOwners = '/settings/project-options/home-owners',
  BuildingDepartmentNotes = '/settings/project-options/building-department-notes',
  BusinessLicenses = '/settings/project-options/business-licenses',
  SalesPersons = '/settings/project-options/sales-persons',
  SalesPersonViewRoot = '/settings/project-options/sales-persons/view',
  SalesPersonView = '/settings/project-options/sales-persons/view/:id',
  SalesPersonCreate = '/settings/project-options/sales-persons/create',
  Installers = '/settings/project-options/installers',
  InstallerViewRoot = '/settings/project-options/installers/view',
  InstallerView = '/settings/project-options/installers/view/:id',
  InstallerCreate = '/settings/project-options/installers/create'
}

export enum ProjectOptionsRouterRelativePathEnum {
  HomeOwners = 'home-owners',
  BuildingDepartmentNotes = 'building-department-notes',
  BusinessLicenses = 'business-licenses',
  SalesPersons = 'sales-persons',
  SalesPersonView = 'sales-persons/view/:id',
  SalesPersonCreate = 'sales-persons/create',
  Installers = 'installers',
  InstallerView = 'installers/view/:id',
  InstallerCreate = 'installers/create'
}

export enum TechniciansAppRouterRelativePathEnum {
  ManageProjects = 'manage-projects',
  ProjectInspections = 'project-inspections/:id',
  ProjectInspectionSteps = 'project-inspection-steps/:id',
  Reports = 'reports/:id',
  ReportsHomeScore = 'reports/home-score/:id',
  ReportsT24 = 'reports/t24/:id',
  ReportsBayren = 'reports/bayren/:id',
  ReportsSmud = 'reports/smud/:id',
  ReportsInspectionAgreement = 'reports/inspection-agreement/:id',
  InspectionAgreement = 'inspection-agreement/:id',
  General = 'general/:id',
  RoofAttic = 'roof-attic/:id',
  Foundation = 'foundation/:id',
  Walls = 'walls/:id',
  Windows = 'windows/:id',
  Heating = 'heating/:id',
  Cooling = 'cooling/:id',
  Duct = 'duct/:id',
  HotWater = 'hot-water/:id',
  PvSystem = 'pv-system/:id',
  Appliances = 'appliances/:id',
  OutdoorFeatures = 'outdoor-features/:id',
  AssessorElectrification = 'assessor-electrification/:id',
  CazTesting = 'caz-testing/:id',
  InfiltrationResults = 'infiltration-results/:id',
  Systems = 'systems/:id',
  InstallVerification = 'install-verification/:id/:type',
  EquipmentInfo = 'equipment-info/:id/:type',
  InstalledDuctSystemInfo = 'installed-duct-system-info/:id/:type',
  AirflowVerification = 'airflow-verification/:id/:type',
  FanWattDraw = 'fan-watt-draw/:id/:type',
  RefrigerantVerification = 'refrigerant-verification/:id/:type',
  DuctLeakageVerification = 'duct-leakage-verification/:id/:type'
}

export enum TechniciansAppRouterPathEnum {
  InstallVerification = '/technicians-app/install-verification',
  InspectionAgreement = '/technicians-app/inspection-agreement',
  HomeScore = '/technicians-app/general',
  T24Alteration = '/technicians-app/systems',
  BPIDataCollection = '/technicians-app/caz-testing',
  Reports = '/technicians-app/reports',
  ReportsHomeScore = '/technicians-app/reports/home-score',
  ReportsT24 = '/technicians-app/reports/t24',
  ReportsBayren = '/technicians-app/reports/bayren',
  ReportsSmud = '/technicians-app/reports/smud',
  ReportsInspectionAgreement = '/technicians-app/reports/inspection-agreement',
  Cheers = '/cheers/cheers-list',
  ProjectInspectionSteps = '/technicians-app/project-inspection-steps'
}

export enum CommScheduleRouterPathEnum {
  CommSchedule = '/comm-schedule'
}

export enum CommScheduleRouterRelativePathEnum {
  CommSchedule = '',
  CommScheduleByProject = ':project'
}

export enum CheersRouterRelativePathEnum {
  Cheers = 'cheers-list/:id'
}

export enum ProjectRequestRouterPathEnum {
  ProjectRequest = '/project-request',
  ProjectDetails = 'project-details'
}
